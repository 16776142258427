//
// Sidebar Minimize
//

.app-sidebar-logo-minimize {
  display: none;
}

// Sidebar footer
.app-sidebar-footer {
  .btn-custom {
    .btn-icon {
      display: none;
    }
  }

  .gegana {
    font-size: $h4-font-size;
  }

  .kbr {
    font-size: $h5-font-size;
  }
}

// Desktop sidebar minimize mode
@include app-layout-minimize(app-sidebar) {
  .app-sidebar-logo {
    .app-sidebar-logo-default {
      display: none;
    }

    .app-sidebar-logo-minimize {
      display: inline-block;
    }
  }

  .app-sidebar-wrapper {
    width: var(--#{$prefix}app-sidebar-width-actual);
  }

  .app-sidebar-user {
    .aside-user-info {
      display: none;
      @include app-layout-transition(display);
    }
  }

  .app-sidebar-menu {
    .menu-content,
    .menu-title {
      opacity: 0;
      @include app-layout-transition(opacity);
    }

    .menu-item.show > .menu-sub {
      height: 0;
      overflow: hidden;
      @include app-layout-transition(height);
    }
  }

  .app-sidebar-footer {
    .btn-custom {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .btn-label {
        width: 0;
        display: none;
      }

      .btn-icon {
        width: auto;
        display: block;
      }
    }

    .gegana {
      font-size: $font-size-base * 0.75;
    }
  
    .kbr {
      font-size: $font-size-base * 0.5;
    }
  }
}

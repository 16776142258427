//
// CKEditor
//

// Base
.ck-target {
  display: none;
}

.ck-toolbar {
  @include border-radius($border-radius !important);
}

.ck-content {
  min-height: 100px;
  background-color: var(--#{$prefix}gray-100) !important;
  border-color: var(--#{$prefix}gray-100) !important;
  @include border-radius($border-radius !important);

  &.ck-focused {
    border-color: var(--#{$prefix}gray-300) !important;
    box-shadow: none !important;
  }
}

.ck-dropdown__panel {
  background-color: var(--#{$prefix}gray-800) !important;
  border-color: var(--#{$prefix}gray-800) !important;
}

// CKEditor Classic & Document
.ck-editor {
  .ck-toolbar {
    background-color: var(--#{$prefix}gray-800) !important;
    border-color: var(--#{$prefix}gray-800) !important;
    @include border-top-radius($border-radius !important);
    @include border-bottom-radius(0 !important);
  }

  .ck-content {
    @include border-bottom-radius($border-radius !important);
    @include border-top-radius(0 !important);
  }

  .ck-button {
    color: #fff !important;

    &.ck-on,
    &:hover {
      background-color: var(--#{$prefix}gray-900) !important;
    }

    &:active {
      box-shadow: var(--#{$prefix}gray-300), 0, 0 !important;
    }

    &.ck-off {
      &.ck-splitbutton__action {
        &:active {
          background-color: var(--#{$prefix}gray-900) !important;
        }
      }

      &.ck-splitbutton__arrow {
        &:active {
          background-color: var(--#{$prefix}gray-800) !important;
          border-color: var(--#{$prefix}gray-800) !important;
        }
      }
    }
  }

  .ck-splitbutton {
    &.ck-splitbutton_open {
      background-color: var(--#{$prefix}gray-900) !important;
      border-color: var(--#{$prefix}gray-900) !important;
    }

    &.ck-dropdown__button {
      &:hover {
        .ck-splitbutton__action,
        .ck-splitbutton__arrow {
          background-color: var(--#{$prefix}gray-900) !important;
        }
      }
    }
  }
}

// CKEditor Inline / Balloon / Balloon Block
.ck-body {
  .ck-balloon-panel {
    .ck-content {
      min-height: 200px;
      border-color: transparent !important;

      &.ck-focused {
        border-color: var(--#{$prefix}primary) !important;
      }
    }

    &.ck-toolbar-container,
    .ck-toolbar {
      @include border-radius($border-radius !important);
    }
  }
}

//
// leaflet
//

.leaflet-container {
  .leaflet-pane,
  .leaflet-top,
  .leaflet-bottom,
  .leaflet-control {
    z-index: 1 !important;
  }

  .leaflet-popup-content-wrapper {
    border-radius: $border-radius !important;
    text-align: center;
    box-shadow: var(--#{$prefix}box-shadow) !important;

    .leaflet-popup-content {
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
    }
  }

  .marker-cluster {
    div {
      background-color: rgba(30, 64, 175, 0.8);
      color: #fff;
      font-family: $font-family-sans-serif;
    }
  }

  .marker-cluster-small {
    background-color: rgba(30, 64, 175, 0.4);
  }
}
